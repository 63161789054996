<template>
	<div class="category-show" :class="type">
		<base-svg :name="chooseIcon(name)" />
		<base-font tag="span" class="category-text">{{lang[name]}}</base-font>
	</div>
</template>
<script>
export default {
	props: {
		name: String,
		type: {
			type: String,
			default: 'white'
		}
	},
	computed: {
		lang () {
			return Object.assign(this.$app.translator.get('products'), this.$app.translator.get('career'))
		}
	},
	methods: {
		chooseIcon (category) {
			return {
				powerSaving: 'eco',
				securityAndHealth: 'safety',
				design: 'design_palette',
				standard: 'standard',
				premium: 'premium',
				exclusive: 'exclusive',
				highlighted: 'highlighted',
				new: 'new'
			}[category]
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
		await this.$app.translator.prefetch('career')
	}
}
</script>
<style lang="scss" scoped>
	.category-show {
        display: flex;
        align-items: center;
		gap: 10px;
        padding: 1rem 2rem;
        background: $white;
        border-radius: .2rem;
		box-shadow: 0 3px 20px rgba($black, .15);
		.category-text {
			color: $secondary;
		}
		.svg-icon {
            width: 15px;
            height: 15px;
        }
		&.product {
			box-shadow: none;
			padding: 1rem 1.6rem;
			::v-deep .category-text {
				text-transform: uppercase;
				font-size: 1.2rem;
			}
		}
		&.blue {
			background: $secondary;
			::v-deep .category-text {
				color: $white;
			}
			::v-deep .svg-icon path {
				fill: $white;
			}
		}
        &.new,&.other {
            padding: .5rem 1rem;
            gap: 5px;
            @include media-breakpoint-up(sm) {
                padding: 1rem 2rem;
                gap: 10px;
            }
        }
	}
</style>
