import { render, staticRenderFns } from "./JobOffersList.vue?vue&type=template&id=66e8cfec&scoped=true"
import script from "./JobOffersList.vue?vue&type=script&lang=js"
export * from "./JobOffersList.vue?vue&type=script&lang=js"
import style0 from "./JobOffersList.vue?vue&type=style&index=0&id=66e8cfec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66e8cfec",
  null
  
)

export default component.exports