<template>
	<section class="section-wrapper career">
        <base-heading tag="h1" heading="main_title" subheading="main_subtitle" v-animate="`slideLeft`"/>
		<base-container>
			<job-offer-item v-for="offer in offers" :key="offer.id" :offer="offer" />
		</base-container>
	</section>
</template>
<script>
import JobOfferItem from '~/career/front/components/JobOfferItem'
import { isPageFromNull } from 'utils/ssrCheck'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
export default {
	components: {
		JobOfferItem,
		BaseHeading
	},
	data () {
		return {
			offers: []
		}
	},
	methods: {
		async fetchOffers () {
			const JobOffer = this.$app.getEntity('job-offer')
			const offers = await JobOffer.fetch()
			this.offers = offers
		},
		recreateDataFromComponentsData () {
			const JobOffer = this.$app.getEntity('job-offer')
			this.offers = this.$app.page.componentsData.offers.map(offer => JobOffer.create(offer))
		}
	},
	computed: {
		value () {
			return this.$app.value
		}
	},
	async mounted () {
		if (isPageFromNull(this.$app)) this.recreateDataFromComponentsData()
		else {
			await this.fetchOffers()
		}
	},
	async prefetch () {
		if (process.server) {
			await this.fetchOffers()

			this.$app.page.componentsData.offers = this.offers
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	position: relative;
	padding: 10rem 0 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 16rem 0 4rem 0;
	}
}
.career {
	&-card {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		border-radius: 4px;
		margin-bottom: 2rem;
		@include media-breakpoint-up(md){
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}
	&-image {
		position: absolute;
		top:0;
		left:0;
		height: 100%;
		width: 100%;
		z-index: -3;
		border-radius: 4px;
	}
	&-button {
		@include transition;
		opacity: 1;
		margin-top: 2rem;
		@include media-breakpoint-up(xl) {
			opacity: 0;
		}
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}
	&-category {
		position: absolute;
		top: 1rem;
		right: 2rem;
	}
	::v-deep .base-image {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}
}
</style>
