<template>
	<base-link class="career-card" to="offer" :params="{ alias: offer.alias }" :title="offer.meta.title"  v-animate="`opacity`">
		<cms-image
			class="career-image"
			lazy
			:src="offer.thumbnail"
			:base="{width: 400, height: 250}"
            :sm="{width: 700, height: 250}"
            :md="{width: 800, height: 300}"
			:lg="{width: 1024, height: 250}"
			:xl="{width: 1440, height: 330}"
            :xxl="{width: 1600, height: 450}"
		/>
		<base-font variant="heading" class="offer-heading" marginReset size="lg" color="white">{{ offer.name[$app.language] }}</base-font>
		<base-button class="career-button">
			<base-svg name="info"/>
			{{generalData.btn_primary_text}}
		</base-button>
		<base-category-show v-if="offer.tag && offer.tag == 'new'" type="blue" class="career-category new" :name="offer.tag"/>
		<base-category-show v-if="offer.tag && offer.tag !== 'new'"  class="career-category other" :name="offer.tag"/>
	</base-link>
</template>

<script>
import BaseButton from '~/website/front/components/atoms/BaseButton/BaseButton.vue'
import BaseLink from '~/website/front/components/atoms/BaseLink/BaseLink.vue'
import BaseCategoryShow from '~/website/front/components/molecules/BaseCategory/BaseCategoryShow.vue'

export default {
	components: {
		BaseButton,
		BaseLink,
		BaseCategoryShow
	},
	async prefetch () {
		await this.$app.translator.prefetch('typography')
	},
	computed: {
		generalData () {
			return this.$app.translator.get('typography')
		}
	},
	props: {
		offer: Object
	}
}
</script>

<style scoped lang="scss">
body.safari {
    .career-image {
        height: 100% !important;
    }
}
.career {
	&-card {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		height: 150px;
		padding: 2rem;
		border-radius: 4px;
		margin-bottom: 2rem;
		overflow: hidden;
        .career-button {
            display: none;
            @include media-breakpoint-up(xl) {
                display: block;
            }
        }
		@include media-breakpoint-up(xl) {
            height: 210px;
			&:hover {
				.career-button {
					opacity: 1;
					transform: translateX(-30px);
				}
			}
		}
        @include media-breakpoint-up(md) {
            padding: 4rem;
            height: 200px;
            min-height: 200px;
        }
        @include media-breakpoint-up(xxl) {
            height: 330px;
        }
		@include media-breakpoint-up(md){
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		&::before {
			content: '';
			position: absolute;
			top:0;
			left: 0;
			background: $secondary;
			opacity: .8;
			width: 10px;
			height: 100%;
			z-index: -1;
            @include media-breakpoint-up(md) {
                width: 20px;
            }
		}
		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 100%;
			top:0;
			left:0;
			background: $primary;
			opacity: .75;
			z-index: -2;
			border-radius: 4px;
		}
        ::v-deep .offer-heading {
            font-size: 1.4rem;
            @include media-breakpoint-up(sm) {
                font-size: 2.4rem;
            }
        }
	}
	&-image {
		position: absolute;
		top:0;
		left:0;
		height: 100%;
		width: 100%;
		z-index: -3;
		border-radius: 4px;
	}
	&-button {
		@include transition;
		opacity: 1;
		margin-top: 2rem;
		@include media-breakpoint-up(xl) {
			opacity: 0;
		}
		@include media-breakpoint-up(md) {
			margin-top: 0;
		}
	}
	&-category {
		position: absolute;
		top: 1rem;
		right: 2rem;
	}
	::v-deep .base-image {
		width: 100%;
		object-fit: cover;
		height: 100%;
	}
}
</style>
